













import Vue from 'vue';
import LinkMembershipRequestViewModel from '../../../../../services/viewModel/request/Memberships/LinkMembershipRequestViewModel';

export default Vue.extend({
  props: {
    value: { type: Object, default: undefined },
  },
  data: () => ({
  }),
  computed: {
    innerValue: {
      get(): LinkMembershipRequestViewModel {
        return this.value ?? {};
      },
      set(value: LinkMembershipRequestViewModel) {
        this.$emit('input', value);
      },
    },
  },
});
